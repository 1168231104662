import { ContactForm } from 'components/forms';
import Head from '../components/Head';
import React from 'react';
import { SiteMetadata } from 'white-label/shared/types';
import { graphql, PageProps } from 'gatsby';
import { Row, Column } from 'components/generic/Grid';
import { ContentBlock } from 'components/generic/ContentBlock';
import { grey10 } from 'styles';

interface GetInTouchPageData {
  site: {
    siteMetadata: Pick<SiteMetadata, 'image'>;
  };
}

const GetInTouchPage = (props: PageProps<GetInTouchPageData>) => {
  return (
    <>
      <Head
        location={props.location}
        title={'Get in Touch'}
        description={'Get in touch with us! We will get back to you as soon as possible.'}
        image={props.data.site.siteMetadata.image}
      />
      <ContentBlock backgroundColor={grey10} mobilePaddingY={'medium'} desktopPaddingY={'large'}>
        <Row>
          <Column tablet={'6/12'} tabletOffset={'4/12'} desktop={'4/12'} desktopOffset={'5/12'}>
            <ContactForm />
          </Column>
        </Row>
      </ContentBlock>
    </>
  );
};

export default GetInTouchPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        image
      }
    }
  }
`;
