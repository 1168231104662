import { ItemInCart, Quote } from 'context/Quote/typedefs';

export function getSimplifiedQuote(quote: Quote) {
  return quote.map((quoteItem: ItemInCart) => {
    return {
      title: quoteItem.product.heading,
      price: quoteItem.product.price,
      // monthlyPrice: quoteItem.product.monthlyPrice,
      quantity: quoteItem.quantity,
      paymentType: quoteItem.paymentType,
      sku: quoteItem.product.selectedVariant?.sku || quoteItem.product.sku,
    };
  });
}

function getDescriptionByCategoryName(categoryName: string) {
  switch (categoryName) {
    case 'Placed Order':
      return 'Project workplace hardware order request';
    case 'Monthly Payments':
    case 'Quote':
      return 'Project workplace hardware discussion';
    default:
      break;
  }
}

export function getEmailFormattedQuote(quote: Quote) {
  const categorized = categorizeOrder(quote);

  const simpleQuote = Object.keys(categorized).reduce((accumulator, categoryName) => {
    const category = categorized[categoryName];
    accumulator[categoryName] = {
      message: getDescriptionByCategoryName(categoryName),
      products: getSimplifiedQuote(category),
    };
    return accumulator;
  }, {} as { [key: string]: { message: ReturnType<typeof getDescriptionByCategoryName>; products: ReturnType<typeof getSimplifiedQuote> } });

  return simpleQuote;
}

function categorizeOrder(items: Quote) {
  const order = items.reduce((everything: { [type: string]: Quote }, item) => {
    const { paymentType } = item;
    if (!paymentType) {
      return everything;
    }
    if (paymentType in everything) {
      delete item.paymentType;
      everything[paymentType].push(item);
      return everything;
    }
    return { ...everything, [paymentType]: [item] };
  }, {});
  return order;
}
