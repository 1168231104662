import { ciscoCountries, isOnServer, useSiteMetadata, useTrackCustomEvent } from 'utils';
import { FormBuilder, useHandleSubmit } from 'components/forms';
import { mediaQueries, mediumBody, branding } from 'styles';
import { navigate } from 'gatsby';
import { PrimaryButton } from 'components/generic/Button';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useQuote } from 'context/Quote/QuoteContext';
import { getEmailFormattedQuote } from 'context/Quote/utils/getSimplifiedQuote';

export function ContactForm() {
  const { submitState, handleSubmit } = useHandleSubmit(branding.formApiEndpoint);
  const { siteUrl } = useSiteMetadata();
  const { quote, quoteId } = useQuote();

  /** Custom Event Tracking */
  const trackCustomEvent = useTrackCustomEvent();
  useEffect(() => {
    switch (submitState.status) {
      case 'sending':
        trackCustomEvent({
          category: 'Contact Form',
          action: '`Send request` clicked',
          label: window.location.pathname,
        });
        break;

      case 'success':
        trackCustomEvent({
          category: 'Contact Form',
          action: '`Send request` response.ok',
          label: window.location.pathname,
        });
        break;

      case 'error':
        trackCustomEvent({
          category: 'Contact Form',
          action: '`Send request` failed',
          label: `${window.location.pathname} - ${submitState.message}`,
        });
        break;
    }
  }, [submitState, trackCustomEvent]);

  return (
    <>
      {submitState.status === 'ready' && (
        <MainSection>
          <h4>Get in touch!</h4>
          <p>
            Please fill out the form below and we will get back to you as soon as possible. Highlighted fields are
            required.
          </p>
          <FormBuilder
            buttonTitle="Send request"
            fields={{
              emailAddress: { type: 'email', label: 'E-mail', required: true },
              firstName: { type: 'text', label: 'First Name', required: true },
              lastName: { type: 'text', label: 'Last Name', required: true },
              company: { type: 'text', label: 'Business/Company', required: true },
              country: { type: 'select', label: 'Country', required: true, options: ciscoCountries },
              busPhone: { type: 'tel', label: 'Phone Number', required: true, placeholder: '(+Code) 123 21 123' },
              zipPostal: { type: 'text', label: 'Zip Code', required: true },
              comments: { type: 'textarea', label: 'Note', required: true },
              customerId: { type: 'text', label: 'Coustomer ID (optional)' },
              preferredPartner: { type: 'text', label: 'Preferred Partner (optional)' },
              elqFormName: { type: 'hidden' },
              elqSiteId: { type: 'hidden' },
              campaign: { type: 'hidden' },
              clickedUrl: { type: 'hidden' },
            }}
            initialValues={{
              emailAddress: undefined,
              firstName: undefined,
              lastName: undefined,
              company: undefined,
              country: undefined,
              busPhone: undefined,
              zipPostal: undefined,
              comments: undefined,
              customerId: undefined,
              preferredPartner: undefined,
              elqFormName: branding.elqFormNameContact,
              elqSiteId: branding.formSiteId,
              campaign: branding.formCampaignId,
              clickedUrl: isOnServer ? 'inserverenv' : window.location.href,
            }}
            onSubmit={value => {
              /**
               * Eloqua want's the data formatted in a special way,
               * so we hook into the submit handeling, and concatenate
               * customerId and prefferedPartner into the description field.
               */

              const description1 = JSON.stringify(
                {
                  quoteId,
                  quote: getEmailFormattedQuote(quote),
                  comments: value.comments,
                  customerId: value.customerId,
                  siteOrigin: siteUrl,
                },
                null,
                2
              );

              handleSubmit({ ...value, description1 });
            }}
          />
        </MainSection>
      )}

      {submitState.status === 'sending' && (
        <MainSection>
          <h4>Sending...</h4>
        </MainSection>
      )}

      {submitState.status === 'error' && (
        <MainSection>
          <h4>Sending failed</h4>
          <p>Please try again...</p>

          <ButtonSection>
            <PrimaryButton clickHandler={() => navigate(-1)}>Back</PrimaryButton>
          </ButtonSection>
        </MainSection>
      )}

      {submitState.status === 'success' && (
        <MainSection>
          <h4>Thank you for your interest!</h4>
          <p>
            Our customers are our highest priority. We will get back to you as soon as possible with a personalised
            offer.
          </p>
        </MainSection>
      )}
    </>
  );
}

const MainSection = styled.div`
  flex-grow: 1;
  h4 {
    margin-bottom: 0.75em;
  }
  p {
    ${mediumBody};
    margin-bottom: 30px;
    ${mediaQueries.large(css`
      margin-bottom: 48px;
    `)}
  }
`;

const ButtonSection = styled.div`
  flex-grow: 0;
  margin-top: 28px;

  ${mediaQueries.small(css`
    margin-top: unset;
  `)}
  ${mediaQueries.large(css`
    margin-top: 28px;
  `)}
`;
