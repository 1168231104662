import { graphql, useStaticQuery } from 'gatsby';
import { SiteMetadata } from 'white-label/shared/types';

interface QueryData {
  site: { siteMetadata: SiteMetadata };
}

export function useSiteMetadata() {
  const data: QueryData = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            brand
            title
            description
            image
            author
            site
            siteUrl
            themeColor
            orderPage {
              title
              url
              type
            }
            phrases {
              disclaimerNonBinding
              disclaimerAdditionalCost
              disclaimerPricingRequest
              buyButtonLabel
            }
          }
        }
      }
    `
  );

  const {
    siteMetadata: { phrases, ...siteMetadata },
  } = data.site;
  const { disclaimerNonBinding, disclaimerAdditionalCost, disclaimerPricingRequest } = phrases;
  const disclaimers = [disclaimerNonBinding, disclaimerAdditionalCost, disclaimerPricingRequest];

  return { ...siteMetadata, phrases: { ...phrases, disclaimers } };
}
